<template>
    <form class='search-box mobile' @submit.prevent="SearchData()">
      <input v-model="search" class="search-text" type="text" placeholder="تبحث عن ماذا ؟">
      <div class="search-btn">
          <i data-feather="search"></i>
      </div>
    </form>
  <nav class="mobile">
    <router-link to="/" class="nav-item">
      <i data-feather="home"></i><span>الرئيسية</span>
    </router-link>
    <router-link to="/Categorie" class="nav-item">
      <i data-feather="server"></i><span>التصنيفات</span>
    </router-link>
    <router-link to="/Poets" class="nav-item">
      <i data-feather="users"></i><span>الشعراء</span>
    </router-link>
    <router-link to="/New" class="nav-item">
      <i data-feather="coffee"></i><span>الجديد</span>
    </router-link>
  </nav>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'AppBottomNavbar',
  setup() {
        const router = useRouter();
        const route = useRoute();
        const search = ref([]);

        const SearchData = () => {
            router.push({ name: "Searchs", params: { search_id: search.value } });
        }
        return { search, SearchData };
    },
  mounted() {
    const s = document.createElement('script');
      s.type = 'text/javascript';
      s. src = '/static/js/father.js';
      document.body.appendChild(s);
  }
}
</script>

<style scoped>
           
nav{
  display: flex;
justify-content: space-around;
background-color: #70499D;
padding: 12px;
position: fixed;
bottom: 0;
width: 100%;
}

.nav-item{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 12px 16px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0);
}

.nav-item span{
  width: 0;
  overflow: hidden;
}

.nav-item:focus{
  text-decoration: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  transition: .2s;
}

.nav-item:focus span{
  width: 100%;
  margin-right: 8px;
  transition: .2s;
}

*:focus{
  outline: none;
  border: none;
}
</style>